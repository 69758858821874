import { useEffect } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'
import { loginUserExchangeToken } from 'services/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import { isNil } from 'ramda'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { fetchAuthUserRoutine } from 'features/auth/ducks/actions'
import { useDispatch } from 'react-redux'

const LoginCallback = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()

  const params = qs.parse(search || '', { ignoreQueryPrefix: true })

  const { ott } = params

  useEffect(() => {
    if (typeof ott === 'string') {
      loginUserExchangeToken({ ott })
        .then(resp => {
          LocalStorageService.set(KEYS.token, resp.data.data.token)
          const redirectPath = LocalStorageService.get(KEYS.redirectPath) || null

          dispatch(fetchAuthUserRoutine())

          setTimeout(() => {
            if (!isNil(redirectPath)) {
              redirect(redirectPath)
              LocalStorageService.remove(KEYS.redirectPath)
            } else {
              redirect(PRIVATE_PATHS.dashboard)
            }
          }, 500)
      })
    }
  }, [ott])

  return null
}

export default LoginCallback
