import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'

export const PhoneField = props => {
  const {
    name,
    onChange,
    validate,
    label,
    value: initialValue,
    disabled,
    clearValueTrigger,
    noMarginBottom,
    noValidation,
    optional
  } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [selectedCountry, setSelectedCountry] = useState({ dialCode: '44', countryCode: 'gb' })
  const [valid, _validate] = useState(false)

  const re = value[2] === '0'
    ? /^(\+?\d{13})$/
    : /^(\+?\d{12})$/

  useEffect(() => {
    _setValue('')
  }, [clearValueTrigger])

  useEffect(() => {
    if (isNotNil(initialValue)) {
      _setValue(initialValue)
    }
    isNotNilOrEmpty(initialValue) && _setTouched(true)
  }, [initialValue])

  const handleBlur = () => {
    _setTouched(true)
  }
  const handleChange = (val, country) => {
    _setValue(val)
    onChange(name, val)
    setSelectedCountry(country)
  }

  useEffect(() => {
    if (noValidation) {
      _validate(true)
    } else if (optional && value === '') {
      _validate(true)
    } else if (value && touched && selectedCountry.countryCode === 'gb') {
      _validate(re.test(value.replace(/\s/g, '')))
    } else if (value && touched && selectedCountry.countryCode !== 'gb') {
      _validate(value.replace(selectedCountry.dialCode, '').length > 5)
    }
  }, [value, selectedCountry, touched, noValidation, initialValue])

  useEffect(() => {
    validate && validate(valid)
  }, [valid])

  return (
    <InputWrapper noMarginBottom={noMarginBottom}>
      <Label>
        {
          valid || disabled
            ? <div>{label} {optional && '(optional)'}</div>
            : touched ? <Error>Incorrect phone number</Error> : <div>{label}</div>
        }
      </Label>
      <PhoneInput
        {...props}
        onBlur={handleBlur}
        country='gb'
        onlyCountries={['gb']}
        onChange={handleChange}
        value={value}
        autoFormat
        isValid={(value, country) => {
          if (optional && value === '') {
            return true
          } else if (noValidation) {
            return true
          } else if (touched && country.iso2 === 'gb') {
            return re.test(value.replace(/\s/g, ''))
          } else {
            return touched && country.iso2 !== 'gb'
          }
        }}
        inputStyle={{
          width: '100%',
          height: '46px'
        }}
        buttonStyle={{
          backgroundColor: 'white'
        }}
      />
    </InputWrapper>
  )
}

PhoneField.defaultProps = {
  onChange: () => {},
  validate: () => {},
  name: '',
  label: '',
  value: '',
  disabled: false,
  clearValueTrigger: '',
  noMarginBottom: false,
  noValidation: false,
  optional: false
}

export default PhoneField

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '25px' };

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .11px;
`

const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
`
