import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp, selectHasAccessToBp } from 'features/bpDetails/ducks/selectors'
import { FOLDERS } from 'utils/bpData'
import BackButton from 'components/atoms/BackButton'
import { pathOr, propOr } from 'ramda'
import Tooltip from '@material-ui/core/Tooltip'
import { ReactComponent as CommentIcon } from 'assets/images/addComment.svg'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import { ReactComponent as FloorplanIcon } from 'assets/images/addFloorplan.svg'
import AnalyticsBox from 'features/bpDetails/Components/AnalyticsBox'
import Folders from 'features/bpDetails/Components/Folders'
import Floorplans from 'features/bpDetails/Components/Floorplans'
import OtherFiles from 'features/bpDetails/Components/OtherFiles'
import ModelPanel from 'features/bpDetails/Components/ModelPanel'
import NotesPanel from 'features/bpDetails/Components/NotesPanel'
import AccessControl from 'features/accessControl/AccessControl'
import UnitsPanel from 'features/bpDetails/Components/units/UnitsPanel'
import BpInfo from 'features/bpDetails/Components/BpInfo/BpInfo'
import styled, { css, keyframes } from 'styled-components'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import history from 'utils/history'
import { fetchFavouritesRoutine } from 'features/bpList/ducks/actions'
import ShortlistButton from 'features/bpDetails/Components/ShortlistButton'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import AddNoteModal from 'features/bpDetails/Components/AddNoteModal'
import { fetchBpRoutine, markBpAsViewedRoutine } from 'features/bpDetails/ducks/actions'
import LogoShort from 'assets/images/bp-logo-orange.png'
import { selectIsFetchingFiles, selectIsFetchingSingleBp } from 'ducks/loaderSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import FilesFolder from 'features/bpDetails/FilesFolder'
import { getBpFilesRoutine, getFileTagsForBpRoutine } from 'ducks/files/actions'
import { fetchBpUnitsRoutine } from 'ducks/units/actions'
import NoticeBoard from 'features/bpDetails/Components/units/NoticeBoard'
import ImageViewer from 'components/ImageViewer'

const BpDetails = () => {
  const { folder, id } = useParams()
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const hasFullAccess = useSelector(selectHasAccessToBp)
  const currentUser = useSelector(getCurrentUser)
  const isFrs = currentUser.role === 'emergency_service'
  const canEdit = canEditBp(currentUser, bp)
  const { location: { pathname } } = useHistory()
  const [isFetched, setIsFetched] = useState(false)
  const [viewed, setViewed] = useState(false)
  const [filesDownloaded, setFilesDownladed] = useState(false)
  const isFetchingBp = useSelector(selectIsFetchingSingleBp)
  const isFetchingFiles = useSelector(selectIsFetchingFiles)

  const isAnalytics = pathOr('', ['location', 'state', 'path'], history) === 'analytics'
  const isDraft = pathname.includes('drafts')
  const isFavourite = pathname.includes('favourites')

  useEffect(() => {
    if (id) {
      dispatch(getFileTagsForBpRoutine({ bpId: id }))
      if (!isFetched) {
        dispatch(fetchBpRoutine({ id, callback: !isFetched ? () => setIsFetched(true) : null }))
        dispatch(fetchBpUnitsRoutine({ bpId: id }))
      }
      if (hasFullAccess && !viewed) {
        dispatch(markBpAsViewedRoutine({ bpId: id }))
        setViewed(true)
       }
      if (!filesDownloaded) {
        dispatch(getBpFilesRoutine({ id: bp.id, callback: !filesDownloaded ? () => setFilesDownladed(true) : null }))
      }
    }
  }, [id, hasFullAccess])

  const backButtonDetails = useMemo(() => {
    switch (true) {
      case isAnalytics:
        return {
          label: 'Back to analytics',
          path: PRIVATE_PATHS.analytics
        }
      case isDraft:
        return {
          label: 'Back to drafts',
          path: PRIVATE_PATHS.drafts
        }
      case isFavourite:
        return {
          label: 'Back to favourites',
          path: PRIVATE_PATHS.savedPassports
        }
      default:
        return {
          label: 'Back to my Building Passports',
          path: PRIVATE_PATHS.bpList
        }
    }
  }, [])

  useEffect(() => {
    if (!FOLDERS.includes(folder) && isNotNilOrEmpty(hasFullAccess)) {
      const initialFolder = hasFullAccess || isFrs ? 'floorplans' : 'units'
      redirect(`${backButtonDetails.path}/${id}/${initialFolder}`)
    }
  }, [folder, hasFullAccess])

  useEffect(() => {
    dispatch(fetchFavouritesRoutine({ page: 1 }))
  }, [])

  const folderContent = useMemo(() => {
    switch (true) {
      case (folder === 'floorplans' || folder === 'frs') && hasFullAccess:
        return <Floorplans />
      case folder === 'other' && hasFullAccess:
        return <OtherFiles />
      case folder === 'files' && hasFullAccess:
        return <FilesFolder />
      case folder === 'model' && hasFullAccess:
        return <ModelPanel />
      case folder === 'notes' && hasFullAccess:
        return <NotesPanel />
      case folder === 'accessControl' && hasFullAccess:
        return <AccessControl />
      case folder === 'noticeBoard':
        return <NoticeBoard />
      case folder === 'units':
        return <UnitsPanel redirectPath={backButtonDetails.path} />
      default:
        return null
    }
  }, [folder, hasFullAccess])

  return (isFetchingBp || isFetchingFiles) && !isFetched ? (
    <Loading>
      <img src={LogoShort} alt='bp-logo' />
      <div>Loading Building Passport</div>
    </Loading>
  ) :(
    <PageWrapper>
      <ImageViewer />
      <ContentWrapper>
        <BackButtonWrapper>
          <BackButton to={backButtonDetails.path}>
            {backButtonDetails.label}
          </BackButton>
          {
            propOr('', 'status', bp) === 'draft' && (
              // eslint-disable-next-line
              <Tooltip title='This Building Passport is still in draft and therefore cannot be accessed by anyone other than the initial creator, including emergency services. Please add to basket and pay the subscription to enable the full suite of platform features.'>
                <DraftLabel>
                  Draft
                </DraftLabel>
              </Tooltip>
            )
          }
        </BackButtonWrapper>
        <TopHeader>
          <PageTitle>
            <p>{bp.name}</p>
            <span>{bp.street}, {bp.city}, {bp.postalCode}</span>
          </PageTitle>
          {
            hasFullAccess && (
              <ActionIcons>
                {
                  canEdit && (
                    <>
                      <AddNoteModal>
                        <Tooltip title='Add note'>
                          <CommentIcon />
                        </Tooltip>
                      </AddNoteModal>
                      <Tooltip title='Add file'>
                        <AddMultipleFilesModal>
                          <FloorplanIcon />
                        </AddMultipleFilesModal>
                      </Tooltip>
                      <ShortlistButton />
                    </>
                  )
                }
              </ActionIcons>
            )
          }
        </TopHeader>
        {
          !isDraft && hasFullAccess && (
            <AnalyticsBox bp={bp} />
          )
        }
        <PanelsWrapper>
          <FoldersPanel>
            <Folders redirectPath={backButtonDetails.path} />
          </FoldersPanel>
          <MainContent>
            {folderContent}
          </MainContent>
        </PanelsWrapper>
      </ContentWrapper>
      <DetailsWrapper>
        <BpInfo redirectPath={backButtonDetails.path} />
      </DetailsWrapper>
    </PageWrapper>
  )
}

export default BpDetails

const PageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: calc(100vw - 440px);
  position: relative;
  padding-bottom: 50px;
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`

const DetailsWrapper = styled.div`
  box-sizing: border-box;
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const PanelsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-top: 38px;
  max-width: 100%;
`

const FoldersPanel = styled.div`
  height: fit-content;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 270px;
  padding: 30px 25px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  border-radius: 4px;
`

export const MainContentPanel = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100px;
  padding: 30px 25px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
`

const MainContent = styled.div`
  box-sizing: border-box;
  margin: 0 0 0 30px;
  width: 100%;
`

export const SectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ noMargin }) => noMargin ? 0 : '20px'};

  ${({ withBorder, theme }) => withBorder && css`
    padding-bottom: 12px;
    border-bottom: 1px solid ${theme.colors.lightGrey};
  `}
`

export const SectionHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: .3px;
  cursor: ${({ withPointer }) => withPointer ? 'pointer' : 'default'};

  span {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
  }
`

export const AddButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .3px;

  ${({ disabled }) => disabled && css`
    cursor: default;
    color: ${({ theme }) => theme.colors.grey[600]};
  `}
`

const DraftLabel = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  opacity: .7;
`

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PageTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 36px;
  align-items: baseline;
  margin-top: 15px;

  &::first-letter {
    text-transform: uppercase;
  }

  p {
    margin-right: 10px;
  }

  span {
    opacity: .5;
    font-size: 16px;
  }
`

const ActionIcons = styled.div`
  display: flex;
  align-self: flex-end;
  padding-bottom: 5px;

  svg {
    margin-left: 17px;
    cursor: pointer;

    &:hover {
      g {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

const pulseAnimation = keyframes`
  0% { transform: scale(0.9); opacity: 0.9 }
  50% { transform: scale(1); opacity: 1 }
  100% { transform: scale(0.9); opacity: 0.9 }
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 300px;

  img {
    width: 100px;
    animation: ${pulseAnimation} 2s infinite;
    margin-bottom: 20px;
  }
`
