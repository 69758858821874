import APIService from './APIService'

const api = APIService()

export const registerUser = payload => api.post('/register', payload)

export const updateUser = payload => api.patch('/me', payload)

export const loginUser = payload => {
  const APIBareUrl = process.env.REACT_APP_API_URL.replace(/(\/api)$/, '')

  return api.get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
    .then(() => api.post('/login', { ...payload } ))
}

export const loginUserExchangeToken = payload => {
  const APIBareUrl = process.env.REACT_APP_API_URL.replace(/(\/api)$/, '')

  return api.get(`${APIBareUrl}/auth/exchange-token?ott=${payload.ott}`, { baseURL: APIBareUrl })
}

export const logoutUser = () => api.get('/logout')

export const fetchAuthUser = () => api.get('/me')

export const fetchAuthUserAccessRequests = () => api.get('/me/access-requests')

export const verifyEmail = payload =>
  api.get(`/verify-email${payload}`)

export const requestResetPassword = payload =>
  api.post('/password/request-reset', payload)

export const resetPassword = payload => api.post('/password/reset', payload)

export const changePassword = payload => api.patch('/me/password', payload)

export const getPublicApiToken = () => api.get('/public-api-token')

export const getApiToken = () => api.get('/internal-api-token')

export const getAccreditationTypes = () => api.get('/dictionaries/accreditations')

export const getUserInvitation = ({ id, email }) => api.get(`/building-passport/invitations/${id}?email=${email}`)
