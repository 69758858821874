import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import MultiFileInput from 'features/bpDetails/Components/files/MultiFileInput'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import NextButton from 'features/createBp/components/atoms/NextButton'
import FileModalTableRow from 'features/bpDetails/Components/files/FileModalTableRow'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import {
  addFileToProcess,
  addUnitFileToProcess, getFilesListForBp,
  startMultiUploadProcess,
  startUnitMultiUploadProcess
} from 'services/fileService'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-toastify'
import BulkEditForm from 'features/bpDetails/Components/files/BulkEditForm'
import {
  clearUploadProcessFetchDetailsRoutine,
  clearUploadProcessRoutine,
  saveUploadProcessFetchDetailsRoutine
} from 'ducks/files/actions'
import FileMinified from 'features/bpDetails/Components/FileMinified'
import Pagination from 'components/atoms/Pagination'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

const AddMultipleFilesModal = ({ externalBpId, children, initialCategory, initialType, unit, isPrivate, addedFilesCount }) => {
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [bulkChangeOpen, setBulkChangeOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [validationState, setValidationState] = useState([])
  const [alreadyAddedFiles, setAlreadyAddedFiles] = useState([])
  const [addedPagePagination, setAddedPagePagination] = useState({
    page: 1,
    pagesCount: 1
  })
  const [payload, setPayload] = useState([])
  const bp = useSelector(getSelectedBp)
  const [bulkChangeValues, setBulkChangeValues] = useState(null)
  const dispatch = useDispatch()
  const isUnit = isNotNilOrEmpty(unit)
  const [isLoading, setLoading] = useState(false)

  const handleSelectAll = () => {
    selectedFiles.length === files.length
      ? setSelectedFiles([])
      : setSelectedFiles(files)
  }

  const handleAddedFilesFetch = page => {
    getFilesListForBp({
      id: externalBpId || bp.id,
      query: {
        page: Math.max(1, page || addedPagePagination.page),
        limit: 10,
        filter: { type: initialType }
      }
    })
    .then(resp => {
      setAddedPagePagination({
        pagesCount: resp.data.meta.lastPage,
        page: resp.data.meta.currentPage
      })
      setAlreadyAddedFiles(resp.data.data)
    })
  }

  const handleModalOpen = () => {
    setIsSubmitted(false)
    setOpen(true)

    if (addedFilesCount && addedFilesCount > 0) {
      handleAddedFilesFetch()
    }
  }

  const handleBulkChangeValues = val => {
    setBulkChangeValues(val)

    setTimeout(() => {
      setSelectedFiles([])
      setBulkChangeValues(null)
    }, 100)
  }

  const handleModalClose = () => {
    setLoading(false)
    setFiles([])
    setSelectedFiles([])
    setPayload([])
    setValidationState([])
    setOpen(false)

    if (addedFilesCount && addedFilesCount > alreadyAddedFiles.length) {
      dispatch(fetchBpRoutine({ id: externalBpId || bp.id }))
    }
  }

  const handleAddFiles = filesToAdd => {
    setFiles(prev => ([
      ...prev,
      ...filesToAdd
    ]))
    setIsSubmitted(false)
  }

  const handleRemove = index => () => {
    const file = files[index]
    setFiles(prev => prev.filter((_, i) => i !== index))
    setSelectedFiles(prev => prev.filter(f => f.lastModified !== file.lastModified))
    const tempValidationState = [...validationState]
    const tempPayload = [...payload]
    tempValidationState.splice(index, 1)
    tempPayload.splice(index, 1)
    setValidationState(tempValidationState)
    setPayload(tempPayload)
  }

  const toggleFileSelection = file => () => {
    const isSelected = isNotNilOrEmpty(
      selectedFiles.find(f => f.name === file.name)
    )
    setSelectedFiles(prev => {
      if (isSelected) {
        return prev.filter(f => f.name !== file.name)
      } else {
        return [
          ...prev,
          file
        ]
      }
    })
  }

  const handleValidationChange = index => value => {
    setValidationState(prev => {
      const newState = [...prev]
      newState[index] = value
      return newState
    })
  }

  const handleValuesChange = index => values => {
    setPayload(prev => {
      const newState = [...prev]
      newState[index] = {
        file: values.file,
        ...values.values
      }
      return newState
    })
  }

  const rows = files.map((file, index) => {
    const findInSelected = selectedFiles.find(f => f.name === file.name)
    const isSelected = isNotNilOrEmpty(findInSelected)

    return (
      <FileModalTableRow
        isPrivate={isPrivate}
        unit={unit}
        initialCategory={initialCategory}
        initialType={initialType}
        isSelected={isSelected}
        isSubmitted={isSubmitted}
        bulkChangeValues={bulkChangeValues}
        key={`file-table-row-${file.name}`}
        file={file}
        onRemove={handleRemove(index)}
        onSelect={toggleFileSelection(file)}
        onValidationStateChange={handleValidationChange(index)}
        onChange={handleValuesChange(index)}
      />
    )
  })

  const headers = isUnit
    ? isPrivate
      ? ['File', '']
      : ['File', 'Category', 'File type', 'Applicable floors', 'Expiration date', '']
    : ['File', 'Category', 'File type', 'Applicable floors', 'Share with', 'Expiration date', '']

  const selectedFilesLength = selectedFiles.map((_, i) => i).length
  const handleBulkChangeToggle = () => setBulkChangeOpen(prev => !prev)

  const isFormValid = validationState.every(v => v)

  const handleSubmit = () => {
    setIsSubmitted(true)
    const bpId = externalBpId || bp.id

    if (isFormValid || isPrivate) {
      setLoading(true)
      dispatch(clearUploadProcessFetchDetailsRoutine())
      dispatch(clearUploadProcessRoutine())

      if (isUnit) {
        startUnitMultiUploadProcess({ bpId, unitId: unit.id, totalFiles: payload.length })
          .then(resp => {
            const processId = resp.data?.data?.id
            dispatch(saveUploadProcessFetchDetailsRoutine({
              processId,
              unitId: unit.id,
              bpId
            }))
            payload.forEach(file => {
              addUnitFileToProcess({
                ...file,
                bpId,
                unitId: unit.id,
                processId,
                isPrivate: isNotNil(isPrivate) ? isPrivate : false
              })
                .catch(e => {
                  toast.error(getApiErrors(e))
                })
            })
            toast.success('Files have been added to upload process')
            handleModalClose()
          })
      } else {
        startMultiUploadProcess({ bpId, totalFiles: payload.length })
          .then(resp => {
            const processId = resp.data?.data?.id
            dispatch(saveUploadProcessFetchDetailsRoutine({
              processId,
              bpId
            }))
            payload.forEach(file => {
              addFileToProcess({
                ...file,
                bpId,
                processId
              })
                .catch(e => {
                  toast.error(getApiErrors(e))
                })
            })
            toast.success('Files have been added to upload process')
            handleModalClose()
          })
          .catch(e => {
            toast.error(getApiErrors(e))
          })
      }
    }
  }

  const handleBulkDelete = () => {
    const selectedIds = selectedFiles.map(file => file.lastModified)
    setFiles(prev => prev.filter(file => !selectedIds.includes(file.lastModified)))
    setSelectedFiles(prev => prev.filter(file => !selectedIds.includes(file.lastModified)))
  }

  return (
    <>
      <Trigger
        onClick={handleModalOpen}
      >
        {children}
      </Trigger>
      {
        open && (
          <Backdrop onClick={handleModalClose}>
            <FakeModal onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>Documents - add new file(s)</ModalTitle>
                <CloseIconWrapper onClick={handleModalClose}>
                  <StyledCloseIcon />
                </CloseIconWrapper>
              </ModalHeader>
              <ModalContent>
                <div>
                  After uploading your file(s), please add the additional information to each file. You can edit
                  multiple files at once by selecting them from the checkboxes on the left. If the file type is not
                  included in the list, you can add this yourself by selecting &apos;Other&apos;.
                  <br /><br />
                  NOTE: The file types in the list are commonly uploaded files but do not necessarily constitute a definitive list
                  of the documentation you will need to ensure compliance with legislation or regulation.
                </div>
                {
                  isNotNilOrEmpty(alreadyAddedFiles) && (
                    <AlreadyUploadedWrapper>
                      <AlreadyUploadedHeader>Uploaded files of this type</AlreadyUploadedHeader>
                      <AlreadyUploadedFiles>
                        {
                          alreadyAddedFiles.map(addedFile => {
                            return (
                              <FileMinified
                                callback={handleAddedFilesFetch}
                                key={`file-${Math.random() * 99}`}
                                file={addedFile}
                                isShared={addedFile.isSharedWithUnits}
                              />
                            )
                          })
                        }
                      </AlreadyUploadedFiles>
                      <Pagination
                        onChange={handleAddedFilesFetch}
                        totalPages={addedPagePagination.pagesCount}
                        currentPage={addedPagePagination.page}
                      />
                    </AlreadyUploadedWrapper>
                  )
                }
                {isNotNilOrEmpty(selectedFiles) && (
                  <SelectedFilesWrapper>
                    <SelectedFilesHeader>
                      {`${selectedFilesLength} selected`}
                      <SelectedActions>
                        {
                          !isPrivate && (
                            <IconWrapper
                              active={bulkChangeOpen}
                              onClick={handleBulkChangeToggle}
                            >
                              <EditIcon/>
                            </IconWrapper>
                          )
                        }
                        <IconWrapper>
                          <DeleteIcon onClick={handleBulkDelete} />
                        </IconWrapper>
                      </SelectedActions>
                    </SelectedFilesHeader>
                    <BulkEditForm
                      isPrivate={isPrivate}
                      isUnit={isUnit}
                      open={bulkChangeOpen && !isPrivate}
                      onChange={handleBulkChangeValues}
                    />
                  </SelectedFilesWrapper>
                )}
                {
                  isNotNilOrEmpty(files) && (
                    <TableContent>
                      <TableHeaders isUnit={isUnit} isPrivate={isPrivate}>
                        <TableHeader key={`header-select-all`}>
                          <RadioCell>
                            <input
                              type='checkbox'
                              onChange={handleSelectAll}
                              checked={selectedFiles.length === files.length}
                            />
                          </RadioCell>
                        </TableHeader>
                        {headers.map(header => <TableHeader key={`header-${header}`}>{header}</TableHeader>)}
                      </TableHeaders>
                      {rows}
                    </TableContent>
                  )
                }
                <SectionTitle>Add new files</SectionTitle>
                <FileInputWrapper>
                  <MultiFileInput
                    accept='.pdf,.dwg,.doc,.docx,.jpg,.jpeg,.txt,.png,.svg,.xlsx,.xls,.xlsm'
                    onAdd={handleAddFiles}
                  />
                </FileInputWrapper>
                {
                  !isFormValid && isSubmitted && (
                    <ErrorsWarning>
                      Fill in the required information highlighted above before these files are added
                    </ErrorsWarning>
                  )
                }
                {
                  isNotNilOrEmpty(files) && (
                    <ButtonWrapper changedMargin={!isFormValid && isSubmitted}>
                      <NextButton
                        onClick={handleSubmit}
                        disabled={isLoading}
                        isLoading={isLoading}
                      >
                        Add new file(s)
                      </NextButton>
                    </ButtonWrapper>
                  )
                }
              </ModalContent>
            </FakeModal>
          </Backdrop>
        )
      }
    </>
  )
}

export default AddMultipleFilesModal

const Trigger = styled.div`
  width: fit-content;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

const FakeModal = styled.div`
  width: 95vw;
  max-width: 1400px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  svg {
    margin-left: 0 !important;
    cursor: default;
  }

  input, option, .MuiSelect-root {
    font-size: 12px !important;
  }
`

const CloseIconWrapper = styled.div`
  width: fit-content;
  padding: 5px;
  cursor: pointer;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
  padding: 0 20px;
`

const ModalContent = styled.div`
  padding: 24px;
  // 100vh - backdrop paddings - modal padding - header height
  max-height: calc(100vh - 60px - 30px - 80px);
  overflow-y: auto;
`

const ModalTitle = styled.div`
  font-weight: bold;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer !important;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const SectionTitle = styled.div`
  font-weight: bold;
  margin: 33px 0 29px;
`

const FileInputWrapper = styled.div`
  padding: 0 20px;
`

const TableContent = styled.div`
  border: 1px solid #EAECF0;
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
`

const TableHeaders = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr repeat(4, 160px) 180px 40px 40px 40px;
  height: 44px;
  background-color: #F7F8FD;
  color: ${({ theme }) => theme.colors.trout};
  border-bottom: 1px solid #EAECF0;

  ${({ isUnit }) => isUnit && css`
    grid-template-columns: 40px 1fr repeat(4, 135px) 40px;
  `}

  ${({ isPrivate }) => isPrivate && css`
    grid-template-columns: 40px auto 40px;
  `}

`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.trout};
`

const ErrorsWarning = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
  padding-top: 20px;
  margin-top: ${({ changedMargin }) => changedMargin ? '24' : '44px;'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[600]};
`

const SelectedFilesWrapper = styled.div`
  padding: 18px 24px;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 4px;
  margin-top: 20px;
`

const SelectedFilesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SelectedActions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const DeleteIcon = styled(DeleteOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.grey[800]};
  cursor: pointer;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.grey[800]};
  cursor: pointer;
`

const IconWrapper = styled.div`
  padding: 5px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
  background-color: ${({ active, theme }) => active ? theme.colors.lightGrey : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey
  }
`

const RadioCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const AlreadyUploadedWrapper = styled.div`
  margin: 30px 0;
`

const AlreadyUploadedHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const AlreadyUploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
