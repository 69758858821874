import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import { getFilesListForBp } from 'services/fileService'
import Pagination from 'components/atoms/Pagination'
import FileMinified from 'features/bpDetails/Components/FileMinified'

const AlreadyUploadedFilesOfTypeModal = ({ children, bpId, type }) => {
  const [open, setOpen] = useState(false)
  const [alreadyAddedFiles, setAlreadyAddedFiles] = useState([])
  const [addedPagePagination, setAddedPagePagination] = useState({
    page: 1,
    pagesCount: 1
  })

  const handleAddedFilesFetch = page => {
    getFilesListForBp({
      id: bpId,
      query: {
        page: Math.max(1, page || addedPagePagination.page),
        limit: 10,
        filter: { type }
      }
    })
    .then(resp => {
      setAddedPagePagination({
        pagesCount: resp.data.meta.lastPage,
        page: resp.data.meta.currentPage
      })
      setAlreadyAddedFiles(resp.data.data)
    })
  }

  const handleOpen = () => {
    setOpen(true)
    handleAddedFilesFetch()
  }
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Trigger onClick={handleOpen}>
        {children}
      </Trigger>
      <Modal
        title='Uploaded files of this type'
        open={open}
        onClose={handleClose}
      >
        <div>
          <AlreadyUploadedFiles>
            {
              alreadyAddedFiles.map(addedFile => {
                return (
                  <FileMinified
                    hideOptions
                    callback={handleAddedFilesFetch}
                    key={`file-${Math.random() * 99}`}
                    file={addedFile}
                    isShared={addedFile.isSharedWithUnits}
                  />
                )
              })
            }
          </AlreadyUploadedFiles>
          <Pagination
            onChange={handleAddedFilesFetch}
            totalPages={addedPagePagination.pagesCount}
            currentPage={addedPagePagination.page}
          />
        </div>
      </Modal>
    </div>
  )
}

export default AlreadyUploadedFilesOfTypeModal

const Trigger = styled.div`
  cursor: pointer;
`

const AlreadyUploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
