import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNil, pathOr, propOr, last } from 'ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import moment from 'moment'
import { removeFileRoutine, updateFileRoutine } from 'ducks/files/actions'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { formatDate } from 'utils/date'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import UnshareFileModal from 'features/bpDetails/Components/UnshareFileModal'
import Modal from 'components/atoms/Modal'
import EditFileModal from 'features/bpDetails/Components/EditFileModal'
import { getFloorOptions } from 'utils/bpData'
import ShareFileModal from 'features/bpDetails/Components/ShareFileModal'
import PdfFullScreenViewer from 'components/PdfFullScreenViewer'
import styled, { css } from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { isNotNilOrEmpty } from 'utils/ramda'
import AddUsersToFile from 'features/bpDetails/Components/files/AddUsersToFile'
import ImageViewer from 'components/ImageViewer'
import { markFileAsViewed } from 'services/fileService'
import ViewedStatusModal from 'features/bpDetails/Components/files/ViewedStatusModal'

const FileMinified = ({ file, isShared, callback, hideOptions }) => {
  const { uploader, expirationDate, displayName } = file
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const hasExpirationDate = useMemo(() => !isNil(expirationDate), [expirationDate])
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false)
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isFloorPlan = pathOr('', ['fileType', 'name'], file) === 'Building plan'
  const canBeAccessed = file?.canBeAccessed || false

  const latestVersionCreationDate = useMemo(() => {
    const versions = propOr([], 'versions', file)
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))

    return last(versions)?.createdAt || null
  }, [file])

  const handlePdfViewerClose = () => setPdfViewerOpen(false)
  const handleImageViewerClose = () => setImageViewerOpen(false)
  const canEdit = canEditBp(currentUser, bp)

  const isExpired = useMemo(() => {
    return hasExpirationDate
      ? moment(expirationDate).isSameOrBefore(moment())
      : false
  }, [hasExpirationDate])

  const openMenu = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }
  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
  }

  const handleModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setModalOpen(true)
  }
  const handleModalClose = () => {
    callback()
    setModalOpen(false)
  }

  const handleEditModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => {
    callback()
    setEditModalOpen(false)
  }

  const handleShareModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setShareModalOpen(true)
  }
  const handleShareModalClose = () => {
    callback()
    setShareModalOpen(false)
  }

  const handleRemoveFile = useCallback(() => {
    dispatch(removeFileRoutine({ id: file.id, callback, bpId: bp.id }))
    handleModalClose()
  }, [file])

  const handlePreviewFile = e => {
    e.stopPropagation()
    e.preventDefault()
    if (/\.pdf/.test(file.url.toLowerCase())) {
      setPdfViewerOpen(true)
    } else if (/\.jpg|\.jpeg|\.png|\.svg|\.webp|\.gif/.test(file.url.toLowerCase())) {
      setImageViewerOpen(true)
    } else {
      window.open(file.url, '_blank')
    }
  }

  const handleDownloadFile = url => e => {
    e.stopPropagation()
    e.preventDefault()
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.target = '_blank'
    anchor.download = file.displayName
    anchor.click()

    const fileId = file.id
    const fileVersionId = pathOr('', ['versions', 0, 'id'], file)

    markFileAsViewed({
      fileId,
      fileVersionId,
      type: 'download'
    })
  }

  const originalUrl = propOr('', 'originalUrl', file)
  const hasOriginalUrl = isNotNilOrEmpty(originalUrl)

  const handleSavePermissions = selectedUsers => {
    if (isNotNilOrEmpty(selectedUsers)) {
      const fileVersionId = pathOr('', ['versions', 0, 'id'], file)
      dispatch(updateFileRoutine({ id: file.id, bpId: bp.id, fileVersionId, body: { userIds: selectedUsers } }))
      setTimeout(() => {
        callback()
      }, 1000)
    }
  }

  return (
    <>
      <FileWrapper
        key={file.id}
        id={file.id}
        canBeAccessed={canBeAccessed}
        isExpired={isExpired}
        onClick={canBeAccessed ? handlePreviewFile : () => {}}
      >
        <NameWrapper>
          <FileIconWrapper>
            <FileIcon />
          </FileIconWrapper>
          <FileName>{displayName}</FileName>
        </NameWrapper>
        <FileDetailsWrapper>
          <DetailsWrapper>
            <Details>
              <p>
                {hasExpirationDate && <ExpiryDate isExpired={isExpired}>
                  {isExpired ? `Expired at:` : `Expiry date:`}&nbsp;
                  {formatDate(expirationDate)}
                </ExpiryDate>}
                {latestVersionCreationDate && <span>Upload&nbsp;date:&nbsp;{formatDate(latestVersionCreationDate)}</span>}
              </p>
              {uploader && <p>Upload by: {propOr('', 'firstName', uploader) + ' ' + propOr('', 'lastName', uploader)}</p>}
            </Details>
          </DetailsWrapper>
          {
            !hideOptions && (
              <IconWrapper onClick={e => e.stopPropagation()}>
                <MoreIcon onClick={canBeAccessed ? openMenu : () => {}} />
                <Menu
                  keepMounted
                  getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  style={{
                    marginTop: '10px'
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handlePreviewFile}>Preview</MenuItem>
                  <MenuItem onClick={handleDownloadFile(file.url)}>{hasOriginalUrl ? 'Download PDF' : 'Download'}</MenuItem>
                  {hasOriginalUrl && <MenuItem onClick={handleDownloadFile(originalUrl)}>Download original file</MenuItem>}
                  {!isShared && canEdit && <MenuItem onClick={handleEditModalOpen}>Edit file details</MenuItem>}
                  {canEdit && (
                    <AddUsersToFile
                      initiallySelected={file?.users || []}
                      onSave={handleSavePermissions}
                      savedCount={file?.users?.length || 0}
                    >
                      <MenuItem>
                        Manage permissions
                      </MenuItem>
                    </AddUsersToFile>
                  )}
                  {canEdit && (
                    <ViewedStatusModal file={file}>
                      <MenuItem>
                        Viewed status
                      </MenuItem>
                    </ViewedStatusModal>
                  )}
                  <MenuItem disabled={isFloorPlan} onClick={handleShareModalOpen}>Share</MenuItem>
                  <UnshareFileModal bp={bp} file={file} />
                  {!isShared && <MenuItem onClick={handleModalOpen}>Remove file</MenuItem>}
                </Menu>
              </IconWrapper>
            )
          }
        </FileDetailsWrapper>
      </FileWrapper>
      <Modal
        open={modalOpen}
        title='Remove file'
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSubmit={handleRemoveFile}
        withSubmit
        withCancel
      >
        Do you really want to remove this file?
      </Modal>
      <EditFileModal
        open={editModalOpen}
        onClose={handleEditModalClose}
        floorOptions={getFloorOptions(bp)}
        file={file}
      />
      <ShareFileModal
        open={shareModalOpen}
        onClose={handleShareModalClose}
        file={file}
      />
      <PdfFullScreenViewer open={pdfViewerOpen} onClose={handlePdfViewerClose} file={file} />
      <ImageViewer open={imageViewerOpen} onClose={handleImageViewerClose} file={file} />
    </>
  )
}

export default FileMinified

const ExpiryDate = styled.span`
  display: inline-block;
  margin-right: 5px;
  color: ${({ theme, isExpired }) => isExpired ? theme.colors.error : theme.colors.haiti};
`

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  box-sizing: border-box;
`

const Details = styled.div`
  font-size: 11px;
  text-align: right;
  color: ${({ theme }) => theme.colors.black};
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 35px !important;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
`

const FileDetailsWrapper = styled.div`
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;
  max-width: 60%;
`

const FileName = styled.div`
  margin-left: 15px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`

const FileWrapper = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  transition: all .3s;
  opacity: ${({ isExpired }) => isExpired ? .6 : 1};
  background-color: ${({ isExpired, theme }) => isExpired ? theme.colors.grey[400] : 'transparent'};
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ canBeAccessed }) => !canBeAccessed && css`
    color: ${({ theme }) => theme.colors.grey[700]} !important;
    cursor: default !important;

    svg {
      cursor: default !important;
    }

    & div {
      color: ${({ theme }) => theme.colors.grey[700]} !important;
    }
    &:hover {
      border-color: ${({ theme }) => theme.colors.grey[700]};
    }
  `}
`

const NameWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 15px;
  max-width: 300px;
  overflow: hidden;

  @media (min-width: 1300px) {
    max-width: 500px;
  }
`
